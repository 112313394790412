import React, { useState, useRef, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import * as classes from './Testimonial.module.css';
import './Testimonial.module.css';
import QuoteText from './QuoteText';

function Testimonial(props) {
  const quoteRef = useRef(null);

  const [showQuote, setShowQuote] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (quoteRef.current.getBoundingClientRect().top - window.innerHeight <= 0) {
        setShowQuote(true);
      }
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [quoteRef]);

  return (
    <div className="row">
      <div style={props.styling} className={`${classes.BlockquoteWrapper} col`}>
        <Transition
          in={showQuote}
          timeout={100}
        >
          {(state) => (
            <QuoteText quoteRef={quoteRef} source={props.source} quote={props.quote} state={state} />
          )}
        </Transition>
      </div>
    </div>
  );
}

export default Testimonial;
