import React from "react"
import { Row, Col } from "react-bootstrap"
import Testimonial from "./Testimonial/Testimonial"

function TestimonialBlock(props) {
  return (
    <Row style={{ background: "#EEEDE7" }}>
      <Col className="text-center text-sm-left">
        {props.data &&
          props.data.map((quote, i) => (
            <Testimonial key={i} source={quote.source} quote={quote.title} />
          ))}
      </Col>
    </Row>
  )
}

export default TestimonialBlock
