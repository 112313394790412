import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/gatsby-starter/seo"
import TestimonialBlock from "../components/About/Testimonials/TestimonialBlock"
import BgImg from "../components/BgImg/BgImg"
import { Heading } from "@chakra-ui/react"

function TestimonialsPage({ data }) {
  return (
    <Layout logo={data?.sanityHomePage?.logo.asset} tagline={data?.sanityHomePage?.tagline}>
      <Seo
        title="Testimonials"
        description="We believe that we have the best staff at Louisville Laser. Read what people say about Louisville Laser!"
      />
        {data?.allSanityHomePage?.edges[0]?.node?.staffPageHeader?.asset?.url && 
        
        <BgImg
          title="Testimonials"
          image={
            data?.allSanityHomePage?.edges[0]?.node?.staffPageHeader?.asset?.url
          }
          styling={{
            filter: "brightness(0.6)",
          }}
        />
      }
          <div className="container-fluid">

        <TestimonialBlock data={data?.allSanityTestimonial?.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityHomePage {
      tagline
      logo {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: AUTO, width: 300)
        }
      }
    }
    allSanityTestimonial {
      nodes {
        source
        title
        id
      }
    }
    allSanityHomePage {
      edges {
        node {
          tagline
          staffPageHeader {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

export default TestimonialsPage
