import React from 'react';
import * as classes from './Testimonial.module.css';
import './Testimonial.module.css';

function QuoteText(props) {
  const duration = 600;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
  };

  return (
    <blockquote
      style={{
        ...defaultStyle,
        ...transitionStyles[props.state],
        background: '#868B8e',
        border: '3px solid #E7D2CC',
      }}
      className="transition blockquote text-center quote"
      ref={props.quoteRef}
    >
      <p className={`${classes.Quote} mb-0`}>{props.quote}</p>
      <footer style={{ color: '#E7D2CC' }} className="blockquote-footer">{props.source}</footer>
    </blockquote>
  );
}

export default QuoteText;
